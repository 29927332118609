<template>
  <span
    v-if="isValid"
    :title="(type === 'difference') ? 'Difference' : 'Percent of ' + type"
    :class="(type === 'difference') ? getPercentClass() : ''"
  >
    {{ (type === 'difference') ? calculatePercentDifference() : calculatePercentOfIncome() }}
    <b-icon-info-circle-fill
      variant="info"
      font-scale="0.75"
      style="vertical-align: text-top;"
    ></b-icon-info-circle-fill>
  </span>
</template>
<script>
export default {
  name: 'PercentDisplay',
  props: {
    oldAmount: Number,
    newAmount: Number,
    type: {
      type: String,
      default: 'income'
    },
    switchComp: {
      type: Boolean,
      default: false
    }
  },

  methods: {
    calculatePercentOfIncome () {
      return (Math.round((Math.round(this.newAmount) / this.oldAmount) * 10000) / 100) + '%'
    },

    calculatePercentDifference () {
      return Math.round(this.oldAmount - this.newAmount) + '%'
    },

    getPercentClass () {
      if (this.switchComp) {
        return (this.newAmount >= this.oldAmount) ? 'text-success' : 'text-danger'
      } else {
        return (this.newAmount <= this.oldAmount) ? 'text-success' : 'text-danger'
      }
    }
  },

  computed: {
    isValid () {
      return (+this.oldAmount !== 0 && +this.newAmount !== 0)
    }
  }
}
</script>
