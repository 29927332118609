<template>
  <b-card no-body>
    <b-card-header>
      <b-row class="pr-0 pr-md-5">
        <b-col
          cols="12"
          md="6"
        ><strong>{{ label }}</strong></b-col>
        <b-col
          class="text-right"
          cols="12"
          md="3"
          offset="0"
          :offset-md="!isComparing ? 3 : 0"
          :class="isComparing ? 'pr-md-0' : ''"
        >
          <b-row no-gutters>
            <b-col class="pl-1"><strong>{{ total | currency | isNegative }}</strong></b-col>
            <b-col class="small text-center">
              <PercentDisplay
                :new-amount="Math.abs(total)"
                :old-amount="income"
                class="pl-3"
              ></PercentDisplay>
            </b-col>
          </b-row>
        </b-col>
        <b-col
          class="text-right"
          cols="12"
          md="3"
          v-if="isComparing"
        >
          <b-row no-gutters>
            <b-col class="pl-3"><strong v-if="compared !== null">{{ compared | compFilt((totalComp !== 0) ? totalComp : 1) | isNegative }}</strong></b-col>
            <b-col class="small">
              <PercentDisplay
                v-if="compared !== null"
                type="difference"
                :switchComp="switchComp"
                :new-amount="income ? (Math.round((Math.round(Math.abs(total)) / income) * 1000) / 10) : 0"
                :old-amount="calcCompPerc(Math.abs(compared), ((totalComp !== 0) ? totalComp : 1))"
                class="pl-3"
              ></PercentDisplay>
            </b-col>
          </b-row>
        </b-col>
      </b-row>
    </b-card-header>
  </b-card>
</template>
<script>
import PercentDisplay from '@/components/profitloss/PercentDisplay'

export default {
  name: 'TotalDisplay',
  components: { PercentDisplay },
  props: {
    label: String,
    total: {
      type: [String, Number],
      default: 0
    },
    compared: {
      type: [String, Number],
      default: null
    },
    isComparing: {
      type: Boolean,
      default: false
    },
    income: {
      type: Number,
      default: 0
    },
    totalComp: {
      type: Number,
      default: 0
    },
    switchComp: {
      type: Boolean,
      default: false
    }
  },

  methods: {
    calcCompPerc (val, comp) {
      return (val / comp) * 100
    }
  },

  filters: {
    isNegative (val) {
      if (val.toString()[0] === '-') {
        return '(' + val.toString().substring(1) + ')'
      }
      return val
    },
    compFilt: function (val, comp) {
      return (Math.round(((val / comp) * 100) * 10) / 10) + '%'
    }
  }
}
</script>
