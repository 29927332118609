<style scoped>
.card {
  border: none !important;
}
.card-header {
  background-color: transparent !important;
  border: none !important;
}
</style>
<template>
  <div>
    <b-card
      no-body
      :class="child == 0 ? 'px-0 px-md-5' : ''"
    >
      <b-card-header
        class="mr-0"
        type="button"
        @click.prevent="collapse = !collapse"
      >
        <b-row>
          <b-col
            cols="12"
            md="6"
            :style="'padding-left: ' + ((child > 3 ? 3 : child) * 3) + 'rem'"
          >
            {{ transactions.accountname }}
            <span
              v-if="transactions.children && transactions.children > 0"
              class="pl-2"
            >
              <b-icon-caret-up-fill v-if="collapse" v-b-toggle="id"></b-icon-caret-up-fill>
              <b-icon-caret-down-fill v-else v-b-toggle="id"></b-icon-caret-down-fill>
            </span>
          </b-col>
          <b-col
            class="text-right"
            offset="0"
            :offset-md="!isComparing ? 3 : 0"
            cols="12"
            md="3"
          >
            <b-row
              no-gutters
              align-v="center"
            >
              <b-col class="pr-3">{{ transactions.balance | currency }}</b-col>
              <b-col class="small text-center pl-0">
                <PercentDisplay
                  v-if="hasIncome"
                  type="income"
                  :new-amount="Math.abs(transactions.balance)"
                  :old-amount="income"
                  class="pl-3"
                ></PercentDisplay>
              </b-col>
            </b-row>
          </b-col>
          <b-col
            class="text-right"
            cols="12"
            md="3"
            v-if="isComparing"
          >
            <b-row
              no-gutters
              align-v="center"
            >
              <b-col>
                <span v-if="compared">{{ Math.abs(compared.balance) | compFilt((totalComp !== 0) ? totalComp : 1) }}</span>
              </b-col>
              <b-col class="small">
                <PercentDisplay
                  v-if="compared"
                  type="difference"
                  :new-amount="income ? (Math.round(Math.abs(transactions.balance)) / income) * 100 : 0"
                  :old-amount="calcCompPerc(Math.abs(compared.balance), ((totalComp !== 0) ? totalComp : 1))"
                  class="pl-3"
                  :switchComp="switchComp"
                ></PercentDisplay>
              </b-col>
            </b-row>
          </b-col>
        </b-row>
      </b-card-header>

      <b-collapse
        :id="id"
        v-if="transactions.children"
      >
      <b-card-body v-for="(t, idx) in subTrans"
        :key="idx" class="p-0">
          <AccountDetail
            :transactions="t"
            :child="child + 1"
            :comapred="null"
            :isComparing="isComparing"
            :income="income"
            :switchComp="switchComp"
            :filters="filters"
          ></AccountDetail>
      </b-card-body>

      </b-collapse>
    </b-card>
  </div>
</template>
<script>
// import AccountSubDetail from '@/components/profitloss/AccountSubDetail'
import PercentDisplay from '@/components/profitloss/PercentDisplay'
import axios from 'axios'

export default {
  name: 'AccountDetail',
  components: { PercentDisplay },
  props: {
    transactions: {
      type: Object
    },
    compared: {
      type: Object,
      default: null
    },
    child: {
      type: Number,
      default: 0
    },
    isComparing: {
      type: Boolean,
      default: false
    },
    income: {
      type: Number,
      default: 0
    },
    totalComp: {
      type: Number,
      default: 0
    },
    switchComp: {
      type: Boolean,
      default: false
    },
    accounttype: {
      type: String,
      default: null
    },
    filters: {
      type: Object
    }
  },

  data () {
    return {
      tempFilt: Object.assign({}, this.filters),
      subTrans: [],
      collapse: false,
      subCollapse: false,
      id: null
    }
  },

  methods: {
    async getChildren (t) {
      this.loading = true
      this.tempFilt.parent_id = t.id
      this.tempFilt.fullname = t.accountname
      return axios({
        url: axios.defaults.baseURL + 'report/getChildren/',
        method: 'POST',
        data: JSON.stringify({ report: 'ProfitLoss', filters: this.tempFilt })
      })
    },

    getChild (t) {
      this.getChildren(t).then((response) => {
        this.subTrans = response.data.info
        this.loading = false
      })
    },

    calculatePercentOfIncome (amount) {
      if (!this.hasIncome || +amount === 0) {
        return false
      }
      return Math.round((Math.round(amount * 100) / this.income) * 10000) / 100
    },

    calculatePercentDifference (newAmount, oldAmount) {
      if (!+oldAmount || !+newAmount) {
        return
      }
      const diff = (newAmount * 100) - (oldAmount * 100)
      return (Math.round((diff / oldAmount * 100)) / 100) + '%'
    },

    getPercentClass (newAmount, oldAmount) {
      return (newAmount > oldAmount) ? 'text-success' : 'text-danger'
    },

    calcCompPerc (val, comp) {
      return (val / comp) * 100
    },

    getRandomId () {
      const num = Math.floor(Math.random() * 10000) + 1
      this.id = 'subCollapse-' + num
    },

    getSubName (t) {
      const result = t.accountname.lastIndexOf(':')
      const name = t.accountname.substring(result + 1, t.accountname.length)
      // eslint-disable-next-line vue/no-mutating-props
      this.transactions.accountname = name
    }
  },

  computed: {
    hasIncome () {
      return (this.income && +this.income !== 0)
    }
  },

  mounted () {
    this.getRandomId()
    this.getChild(this.transactions)
    this.getSubName(this.transactions)
  },

  filters: {
    compFilt: function (val, comp, string = true) {
      return (Math.round(((val / comp) * 100) * 10) / 10) + '%'
    }
  }
}
</script>
